<template>
  <div class="my-6">
    <h3 class="font-bold">List of {{ route.name }}</h3>
    <span class="text-sm text-gray-500"
      >Here you can observe all items related to the {{ route.name.toLowerCase() }}.
    </span>
  </div>
  <div class="flex mb-6 justify-end">
    <router-link to="/users/create">
      <Button color="primary" :text="`Create new ${(route.name.slice(0, -1)).toLowerCase()}`" />
    </router-link>
  </div>
  <div class="card">
    <DataTable
      :headers="headers"
      :data="data"
      :options="options"
      color="primary"
    />
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "users",
  components: {
    DataTable,
    Button,
  },
  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Name", value: "name" },
      { key: "Function", value: "user_function" },
      { key: "Last Update", value: "updated_at", date: true},
      { key: "Options", value: "" },
    ];

    const route = useRoute();
    const router = useRouter();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);

    onMounted(async () => {
      try {
        loader.open();
        data.value = await GET("users");
        //data.value = data.value;
        //data.value.map( partner => partner.status = partner.deleted_at ? 'INATIVA' : 'ATIVA');
        loader.close();
      } catch (e) {
        loader.close();
        if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
          localStorage.removeItem("t_prefab");
          localStorage.removeItem("info_prefab");
          router.push({ path: '/login'});
        }
        alert.open("Error", e, "danger");
      }
    });

    const deleteItem = (user) => {
      loader.open();
      const deleteFunction = async function() {
        try{
          loader.open();
          await DELETE(`users/${user.id}`);
          data.value = data.value.filter((item) => item.id != user.id);
          loader.close();
          alert.open("Success!", `User <b>${user.name}</b> was deleted successfully!`, "success");
        }catch(e){
          loader.close();
          if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
            localStorage.removeItem("t_prefab");
            localStorage.removeItem("info_prefab");
            router.push({ path: '/login'});
          }
          alert.open("Error", e, "danger");
        }
      }
      modal.open(
        "Attention",
        "Do you really wish to delete this user?",
        "warning",
        "Yes",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };

    // const show = (r) => {
    //   router.push(`/parceiros/editar/${r.id}`);
    // };

    const options = [
      { icon: "pen", path: '/users/edit', title: "Edit user" },
      { icon: "trash", action: (param) => deleteItem(param), title: "Delete user" },
    ];

    return { headers, data, options, route };
  },
};
</script>

<style>
</style>