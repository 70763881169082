<template>
  <div class="my-6">
    <h3 class="font-bold">
      Create a new {{ route.name.split("/")[0].slice(0, -1).toLowerCase() }}
    </h3>
    <span class="text-sm text-gray-500"
      >Fill the following information and click the <b>create</b> button to save changes.
    </span>
  </div>

  <div class="card shadow-sm bg-white border p-8">
    <span class="lg:inline-grid w-full grid-cols-3 gap-x-4 mb-6">
      <div class="flex-1 mr-3">
        <Input
          v-model="data.name"
          type="default"
          label="Name"
          placeholder="Project Name"
        />
      </div>
      <div class="flex-1 mr-3">
        <Input
          v-model="data.sprint"
          type="default"
          label="Sprint"
          placeholder="Sprint"
        />
      </div>
      <Input
          v-model="data.required_hours"
          type="default"
          label="Required Hours"
          placeholder="Hours"
        />
    </span>
  </div>

  <div class="flex justify-end mt-5">
    <Button @click="create" color="primary" text="Create" />
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET ,POST } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "createProjects",
  components: {
    Input,
    Button,
  },

  setup() {
    const alertProps = inject("alert");
    const data = ref({
      name: "",
      sprint: "",
      required_hours: "",
    });
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();
    const institutions = ref([]);

    onMounted(async() => {
      loader.open();
      try {
        institutions.value = await GET('projects');
        loader.close();
      } catch(e) {
        loader.close();
        if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
          localStorage.removeItem("t_prefab");
          localStorage.removeItem("info_prefab");
          router.push({ path: '/login'});
        }
        alert.open("Error", e, "danger");
      }
    })

    const create = async () => {
      const obj = { name: data.value.name }
      const validate = isEmpty(obj);
      if (validate) {
        validate && alert.open("Attention!", validate, "warning");
      } else {
        loader.open();
        try {
          const inputToHours = data.value.required_hours * 60;
          data.value.required_hours = inputToHours;
          console.log(inputToHours);
          await POST("projects", data.value);
          alert.open(
            "Success!",
            `Project successfully created!`,
            "success"
          );
          loader.close();
          router.push("/projects");
        } catch (e) {
          alert.open("Attention!", e, "danger");
          loader.close();
          if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
            localStorage.removeItem("t_prefab");
            localStorage.removeItem("info_prefab");
            router.push({ path: '/login'});
          }
        }
      }
    };

    return {
      alertProps,
      data,
      create,
      route,
      institutions
    };
  },
};
</script>

<style>
</style>