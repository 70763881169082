const isEmpty = (payload: object)  => {
    const emptyFields: Array<string | void> = []
    Object.entries(payload).forEach(element => {
        if(element[1] === "" || element[1] === '0' || element[1] == undefined){
            element[0] !== 'deleted_at' && emptyFields.push(element[0]);
        }
    });
    let returnMessage = ""
    emptyFields.forEach(el => {
        el === 'name' ? el = 'Name' : el;
        el === 'worked_hour' ? el = 'Time Spent' : el;
        el === 'description' ? el = 'Description' : el;
        el === 'projects_id' ? el = 'Project' : el;
        el === 'activities_id' ? el = 'Activity' : el;
        el === 'categories_id' ? el = 'Category' : el;
        el === 'disciplines_id' ? el = 'Discipline' : el;
        el === 'users_id' ? el = 'User' : el;
        el === 'user_function' ? el = 'Occupation' : el;
        el === 'email' ? el = 'Email' : el;
        el === 'password' ? el = 'Password' : el;
        el === 'sprint' ? el = 'Sprint' : el;
        el === 'required_hours' ? el = 'Required Hours' : el;
        el === 'worked_date' ? el = 'Worked Date' : el;
        
        returnMessage += `* The <b>${el}</b> field is empty <br/>`
    })
    return returnMessage
}

const isEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const isUniqueCode = (code: string): boolean => {
    const re = /^[A-Za-z0-9]*\d+[A-Za-z0-9]*$/;
    return re.test(code);
}





export { isEmpty, isEmail, isUniqueCode }