

const List = [
    { icon: 'home', label: 'Dashboard', name: "Home", path: '/',  component: () => import('../views/Home.vue'),},

    /*//Instituições
    { icon: 'briefcase', label: 'Instituições', name:"Instituições", path: '/instituicoes',  component: () => import('../views/Instituicoes/index.vue') },
    { show: false, name:"Instituições/Criar", path: '/instituicoes/criar',  component: () => import('../views/Instituicoes/create.vue') },
    { show: false, name:"Instituições/Editar", path: '/instituicoes/editar/:id?',  component: () => import('../views/Instituicoes/update.vue') },*/

    //Projects
    { icon: 'list', label: 'Projects', name:"Projects", path: '/projects',  component: () => import('../views/Projects/index.vue') },
    { show: false, name:"Projects/Create", path: '/projects/create',  component: () => import('../views/Projects/create.vue') },
    { show: false, name:"Projects/Edit", path: '/projects/edit/:id?',  component: () => import('../views/Projects/update.vue') },
    
    //Users
    { icon: 'user-list', label: 'Users', name:"Users", path: '/users',  component: () => import('../views/Users/index.vue')},
    { show: false, name:"Users/Create", path: '/users/create',  component: () => import('../views/Users/create.vue') },
    { show: false, name:"Users/Edit", path: '/users/edit/:id?',  component: () => import('../views/Users/update.vue') },
    
    //Records
    { show: false, name:"Records/Create", path: '/records/create',  component: () => import('../views/Records/create.vue') },
    { show: false, name:"Records/Edit", path: '/records/edit/:id?',  component: () => import('../views/Records/update.vue') },
    
    /*//Comentários
    { icon: 'comment', label: 'Comentários', name:"Comentários", path: '/comentarios',  component: () => import('../views/Comentarios/index.vue')},
    // { show: false, name:"Comentários/Criar", path: '/comentarios/criar',  component: () => import('../views/Comentarios/create.vue') },
    { show: false, name:"Comentários/Editar", path: '/comentarios/editar/:id?',  component: () => import('../views/Comentarios/update.vue') },*/


    //Atividades 

    { icon: 'brackets', label: 'Activities', name:"Activities", path: '/activities',  component: () => import('../views/Activities/index.vue') },
    { show: false, name:"Activities/Create", path: '/activities/create',  component: () => import('../views/Activities/create.vue') },
    { show: false, name:"Activities/Edit", path: '/activities/edit/:id?',  component: () => import('../views/Activities/update.vue') },

    //labels
    { icon: 'laptop', label: 'Labels', name:"Labels", path: '/labels',  component: () => import('../views/Labels/index.vue') },
    { show: false, name:"Labels/Create", path: '/labels/create',  component: () => import('../views/Labels/create.vue') },
    { show: false, name:"Labels/Edit", path: '/labels/edit/:id?',  component: () => import('../views/Labels/update.vue') },


    //categorias 

    { icon: 'menu-boxed', label: 'Categories', name:"Categories", path: '/categories',  component: () => import('../views/Categories/index.vue') },
    { show: false, name:"Categories/Create", path: '/categories/create',  component: () => import('../views/Categories/create.vue') },
    { show: false, name:"Categories/Edit", path: '/categories/edit/:id?',  component: () => import('../views/Categories/update.vue') },

    //discipline

    
    { icon: 'menu-grid-o', label: 'Disciplines', name:"Disciplines", path: '/disciplines',  component: () => import('../views/Disciplines/index.vue') },
    { show: false, name:"Disciplines/Create", path: '/disciplines/create',  component: () => import('../views/Disciplines/create.vue') },
    { show: false, name:"Disciplines/Edit", path: '/disciplines/edit/:id?',  component: () => import('../views/Disciplines/update.vue') },

    

]

export default List;