
import { watch, ref } from "vue";
import { toDate, toMoney, numberToCpf, numberToCnpj } from "../../services/formater";
export default {
  props: {
    headers: Array,
    data: Array,
    options: Array,
    color: String,
    exports: Boolean,
  },
  setup(props: any) {
    const query = ref("");
    const copyData: any = ref();
    const perView = ref(5);
    const pagination = ref();
    const indexPagination = ref(0);
    const sizeOfList = ref();
    const stateSort = ref("asc");

    const convertToCSV = (objArray) => {
      const temp = objArray
      const array = typeof objArray != "object" ? JSON.parse(temp) : temp;
      let str = "";

      for (let i = 0; i < array.length; i++) {
        let line = "";
        for (const index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    };

    const exportCSVFile = (headers, items, fileTitle) => {
  
      // Convert Object to JSON
      const data = [...items]
      const head = headers.filter(el => el.key != "Opções")
      const headValues = head.map(hd => hd.value)
      const obj = data.map((el) => {
        const properties = Object.getOwnPropertyNames(el);
        properties.filter(pt => {
          if(!headValues.includes(pt))
            delete el[pt]
        });
        return el
      })
      
      obj.unshift(head.map(el => el.key))
      const jsonObject = JSON.stringify(obj);
      
      const csv = convertToCSV(jsonObject);

      const exportedFilename = fileTitle + '.csv' || 'export.csv';

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilename);
      } else {
          const link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
              // Browsers that support HTML5 download attribute
              const url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportedFilename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
      }
    }



    const deep = (item: object, pos: string): any => {
      let payload = item;
      pos.split("][").map((el) => {
        payload = payload[el.replace(/[^\w\s]/gi, "")]
          ? payload[el.replace(/[^\w\s]/gi, "")]
          : payload;
      });

      return payload;
    };

    const find = (query: string) => {
      const response: Array<any | null> = [];
      props.data.map((item: object, index: number) => {
        Object.entries(item).map((element) => {
          if (!response.includes(props.data[index])) {
            if (String(element[1]).toLowerCase() === query.toLowerCase()) {
              response.unshift(props.data[index]); //Items Iguais!
            } else {
              if (
                String(element[1]).toLowerCase().includes(query.toLowerCase())
              ) {
                response.push(props.data[index]); //Items semelhantes!
              }
            }
          }
        });
      });
      return response;
    };

    const divide = (payload: Array<any> | any, divider: number | any) => {
      const divideArray = [...payload];
      return new Array(Math.ceil(divideArray.length / divider))
        .fill(null)
        .map(() => divideArray.splice(0, divider));
    };

    const sortTable = (type: string, key: string, param: any) => {
      stateSort.value = type;
      const sortData: Array<any> = [];

      pagination.value.map((el: Array<any>) => {
        el.map((item: object) => {
          sortData.push(item);
        });
      });

      if (param == "number") {
        if (type == "dec") {
          sortData.sort((a: any, b: any) => b[key] - a[key]);
        } else {
          sortData.sort((a: any, b: any) => a[key] - b[key]);
        }
      } else {
        if (type == "dec") {
          sortData.sort((a: any, b: any) =>
            String(b[key]).localeCompare(String(a[key]))
          );
        } else {
          sortData.sort((a: any, b: any) =>
            String(a[key]).localeCompare(String(b[key]))
          );
        }
      }

      pagination.value = divide(sortData, Number(perView.value));
      copyData.value = pagination.value[0];
    };

    watch(props, () => {
      copyData.value = props.data;
      const divideData = divide(copyData.value, Number(perView.value));
      copyData.value = divideData[0];
      pagination.value = divideData;
      sizeOfList.value = pagination.value.length;
      indexPagination.value = 0;
    });

    watch(query, () => {
      const result = find(query.value);
      if (result.length > 0) {
        pagination.value = divide(result, perView.value);
        copyData.value = pagination.value[0];
      } else {
        copyData.value = [];
      }
    });

    watch(perView, () => {
      pagination.value = divide(props.data, Number(perView.value));
      copyData.value = pagination.value[0];
      sizeOfList.value = pagination.value.length;
    });

    watch(indexPagination, () => {
      copyData.value = pagination.value[indexPagination.value];
    });

    return {
      query,
      props,
      copyData,
      perView,
      pagination,
      indexPagination,
      sizeOfList,
      sortTable,
      stateSort,
      toDate,
      toMoney,
      numberToCpf,
      numberToCnpj,
      deep,
      exportCSVFile,
    };
  },
};
