<template>
  <div class="home">
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Dashboard
    </h2>

    <hr class="my-9" />

    <div class="card shadow-lg border rounded-lg p-8 bg-white mt-10">
      <div class="w-full">
        <div class="flex-3 w-full">
          <h3 class="font-bold">Intelligence Graphs</h3>
          <span class="text-sm text-gray-500">
            Here you can analyze employees hour information, by filtering range of days and projects.
          </span>
        </div>

        <div class="flex mb-6 justify-end">
          <router-link to="/records/create">
            <button
              type="button"
              :class="`btn-outline-primary 
              transition duration-300 ease-in-out 
              bg-primary text-white
              focus:outline-none focus:shadow-outline border
              font-bold py-2 mt-1 w-5/4 px-10 text-sm rounded-md items-center h-full hover:bg-yellow-500`"
            >
              Create new record
            </button>
          </router-link>
        </div>

        <div class="my-9 flex items-center">
          <div class="flex-1 mr-2">
            <Input
              type="date"
              label="Initial Date"
              v-model="initialDate"
              :maxDate="finalDate"
            />
          </div>          
          <div class="flex-1 ml-2">
            <Input
              type="date"
              label="Final Date"
              v-model="finalDate"
              :minDate="initialDate"
            />
          </div>
          <div class="flex-1 ml-2">
            <Input
              type="select"
              label="Project"
              placeholder="Select a project"
              v-model="projectFilter"
              :data="projectFilterList"
            />
          </div>
          <div class=" ml-4 items-center">
            <button
              type="button"
              :class="`btn-outline-primary 
              transition duration-300 ease-in-out 
              bg-primary text-white
              focus:outline-none focus:shadow-outline border
              font-bold py-4 mt-1 w-5/4 px-10 text-sm rounded-md items-center h-full hover:bg-yellow-500`"
              @click="filterRecordsByDate"
            >
              Filter 
            </button>
            
          </div>
          
        </div>

        <div class="card">
          <DataTable
            :headers="headers"
            :data="data"
            :options="options"
            color="primary"
          />
        </div>

        <div class="flex justify-between items-center">
          <div>
            <div class="mt-2"> 
              <span class="text-base font-bold text-gray-800 text-center ml-2">
                Project hours: {{projectHours}} h
              </span>
            </div>
            <div>
              <span class="text-base font-bold text-gray-800 text-center ml-2">
                Used hours: {{total}} h
              </span>
            </div>
            <div>
              <span class="text-base font-bold text-gray-800 text-center ml-2">
                Available total: {{availableTotal}} h
              </span>
            </div>
          </div>
          
          <div>
             <button
              type="button"
              :class="`btn-outline-primary 
              transition duration-300 ease-in-out 
              bg-primary text-white
              focus:outline-none focus:shadow-outline border
              font-bold py-2 mt-1 w-5/4 px-10 text-sm rounded-md items-center h-full hover:bg-yellow-500`"
              @click="selectAll"
              v-if="delete_array.length == 0"
            >
              Select All
            </button>


            <button
              type="button"
              :class="`btn-outline-primary 
              transition duration-300 ease-in-out 
              bg-primary text-white
              focus:outline-none focus:shadow-outline border
              font-bold py-2 mt-1 w-5/4 px-10 text-sm rounded-md items-center h-full hover:bg-yellow-500`"
              @click="unSelectAll"
              v-if="delete_array.length != 0"
            >
              Unselect All
            </button>

             <button
              type="button"
              :class="`btn-outline-primary 
              transition duration-300 ease-in-out 
              bg-primary text-white
              focus:outline-none focus:shadow-outline border
              font-bold py-2 mt-1 w-5/4 px-10 text-sm rounded-md items-center h-full hover:bg-yellow-500`"
              @click="deleteArray"
            >
              Delete
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, onMounted, ref } from "vue";
import DataTable from "../components/Elements/Datatable.vue";
import Input from "../components/Elements/Input.vue";
import { GET, DELETE,PUT } from "../services/api";
import { useRouter } from "vue-router";
import moment from "moment";

export default defineComponent({
  name: "Home",
  components: {
    Input,
    DataTable
  },
  setup() {


    const delete_array = ref([]);


    const addDelete = (param) =>{
      const found = delete_array.value.find(el => el == param)
      if(found)
      {
        const pos = delete_array.value.findIndex(el => el == param)

        delete_array.value.splice(pos,1)

        console.log("removendo " ,param)
      }else{
        console.log("add " ,param)
        delete_array.value.push(param)
      }
    }

    const headers = [
      { tick:true , value: "id",  action: (param) => addDelete(param) },
      { key: "User", value: "users_id" },      
      { key: "Work date", value: "worked_date", date: true},
      { key: "Time spent", value: "worked_hour",},
      { key: "Description", value: "description" },
      { key: "Inclusion date", value: "created_at", date: true },
      { key: "Options", value: "" },
    ];
    const data = ref([]);
    const total = ref(0);
    const projectHours = ref(0);
    const availableTotal = ref(0);

    const componentKey = ref(0);
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal");
    const router = useRouter();

    const initialDate = ref("");
    const finalDate = ref("");
    const projectFilter = ref("-");
    const projectFilterList = ref([]);
    const acesso = ref([0,0]);
    const users = ref([0,0]);
    const projects = ref('');

    const recordsByDateAndProject = ref({});

    const filterRecordsByDate = async () => {
      loader.open();
      try{
        if (initialDate.value === '' || finalDate.value === '' || projectFilter.value === '') {
          initialDate.value === '' && alert.open("Warning", 'Initial date was not informed.' , "warning");
          finalDate.value === '' && alert.open("Warning", 'Final date was not informed.' , "warning");
          projectFilter.value === '' && alert.open("Warning", 'Project was not informed.' , "warning");
        } else {
          recordsByDateAndProject.value = await GET(`/hours_record/${initialDate.value}/${finalDate.value}/${projectFilter.value}`);
          data.value = recordsByDateAndProject.value.map(name => {
            const splitHours = name.worked_hour.split(":");
            return {...name,users_id: name.users_id.name,
              worked_hour: splitHours[0] + ":" + splitHours[1]
            }
          })

          const totalProjectHours = await GET(`/projects/${projectFilter.value}`);
          projectHours.value = (totalProjectHours.data.required_hours/60) + ":00";


          const hoursArray = data.value.map(item => {
            return item.worked_hour;
          })
          const projectHoursInMs = moment.duration(projectHours.value)
  
          //somando todas as horas trabalhadas
          const sum = hoursArray.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());
  
          //subtraindo as horas do projeto com a soma de todas as horas trabalhadas
          const remainingTime = projectHoursInMs.subtract(sum);

          total.value = [Math.floor(sum.asHours()), sum.minutes()].join(':');
          availableTotal.value = [Math.floor(remainingTime.asHours()), remainingTime.minutes()].join(':');
          
          total.value = total.value.split(":");
          availableTotal.value = availableTotal.value.split(":");
          
          let correctMinutes = total.value[1];
          let correctHours = total.value[0];

          let correctMinutesRemaining = availableTotal.value[1];
          
          //checks if the minutes are negative
          if(Number(correctMinutesRemaining) < 0) {
            correctMinutesRemaining = Math.abs(correctMinutesRemaining);
          }

          let correctHoursRemaining = availableTotal.value[0];
          
          if (correctMinutes.length == 1){
            correctMinutes = "0" + correctMinutes;
          }
          if (correctMinutesRemaining.length == 1){
            correctMinutesRemaining = "0" + correctMinutesRemaining;
          }

          if (correctHours.length == 1){
            correctHours = "0" + correctHours;
          }
          if (correctHoursRemaining.length == 1){
            correctHoursRemaining = "0" + correctHoursRemaining;
          }

          availableTotal.value = correctHoursRemaining + ":" + correctMinutesRemaining;
          total.value = correctHours + ":" + correctMinutes;
        }
      } catch(e) {
        console.log(e);
        if (e === 'Access data not found.') componentKey.value += 1;
        else alert.open("Error", e, "danger");
      }
      loader.close();
    };

    onMounted(async () => {
      loader.open();
      const date = new Date();
      const finalDateArray = date.toLocaleDateString().split("T")[0].split("/");
      date.setDate(date.getDate() - 3);
      const initialDateArray = date.toLocaleDateString().split("T")[0].split("/");
      console.log(initialDate.value);
      console.log(date.toLocaleDateString("en-US"));
      initialDate.value = `${initialDateArray[2]}-${initialDateArray[1]}-${initialDateArray[0]}`;
      finalDate.value = `${finalDateArray[2]}-${finalDateArray[1]}-${finalDateArray[0]}`;
      try {
        const responseProjects = await GET('/projects');
        projects.value = responseProjects;
        if (responseProjects.length > 0) {
          projects.value.map( projeto =>  projectFilterList.value.push({label: projeto.name, value: projeto.id}));
          projectFilter.value = projectFilterList.value[0].value;
        }
        loader.close();
      } catch (e) {
        loader.close();
        if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
          localStorage.removeItem("t_prefab");
          localStorage.removeItem("info_prefab");
          router.push({ path: '/login'});
        }
      }

    });

    const deleteItem = (record) => {
      loader.open();
      const deleteFunction = async function() {
        try{
          loader.open();
          await DELETE(`hours_record/${record.id}`);
          data.value = data.value.filter((item) => item.id != record.id);

          const hoursArray = data.value.map(item => {
          return item.worked_hour;
        })

        if (hoursArray != null && hoursArray.length >= 1){
          const projectHoursInMs = moment.duration(projectHours.value)
          const sum = hoursArray.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());
          const remainingTime = projectHoursInMs.subtract(sum);
          total.value = [Math.floor(sum.asHours()), sum.minutes()].join(':');
          availableTotal.value = [Math.floor(remainingTime.asHours()), remainingTime.minutes()].join(':');
          total.value = total.value.split(":");
          availableTotal.value = availableTotal.value.split(":");
          
          let correctMinutes = total.value[1];
          let correctHours = total.value[0];

          let correctMinutesRemaining = availableTotal.value[1];
          let correctHoursRemaining = availableTotal.value[0];

          if (correctMinutes.length == 1){
            correctMinutes = "0" + correctMinutes;
          }
          if (correctMinutesRemaining.length == 1){
            correctMinutesRemaining = "0" + correctMinutesRemaining;
          }

          if (correctHours.length == 1){
            correctHours = "0" + correctHours;
          }
          if (correctHoursRemaining.length == 1){
            correctHoursRemaining = "0" + correctHoursRemaining;
          }

          availableTotal.value = correctHoursRemaining + ":" + correctMinutesRemaining;
          total.value = correctHours + ":" + correctMinutes;
        }
          else{
            availableTotal.value = 0;
            total.value = 0;
          }
          
          loader.close();
          alert.open("Success!", `Record was deleted successfully!`, "success");
        }catch(e){
          loader.close();
          if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
            localStorage.removeItem("t_prefab");
            localStorage.removeItem("info_prefab");
            router.push({ path: '/login'});
        }
        alert.open("Error", e, "danger");
        }
      }
      modal.open(
        "Attention",
        "Do you really wish to delete this record?",
        "warning",
        "Yes",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };
    


    const deleteArray = () => {
      loader.open();
      const deleteFunction = async function() {
        if(delete_array.value.length > 0)
        {
          try{
          loader.open();
          
          await PUT(`hours_record/delete_array`, delete_array.value);
         
          for(const x of delete_array.value )
          {

            
            data.value = data.value.filter((item) => item.id != x);
          }

          const hoursArray = data.value.map(item => {
            return item.worked_hour;
          })

        if (hoursArray != null && hoursArray.length >= 1){
          const projectHoursInMs = moment.duration(projectHours.value)
          const sum = hoursArray.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());
          const remainingTime = projectHoursInMs.subtract(sum);
          total.value = [Math.floor(sum.asHours()), sum.minutes()].join(':');
          availableTotal.value = [Math.floor(remainingTime.asHours()), remainingTime.minutes()].join(':');
          total.value = total.value.split(":");
          availableTotal.value = availableTotal.value.split(":");
          
          let correctMinutes = total.value[1];
          let correctHours = total.value[0];

          let correctMinutesRemaining = availableTotal.value[1];
          let correctHoursRemaining = availableTotal.value[0];

          if (correctMinutes.length == 1){
            correctMinutes = "0" + correctMinutes;
          }
          if (correctMinutesRemaining.length == 1){
            correctMinutesRemaining = "0" + correctMinutesRemaining;
          }

          if (correctHours.length == 1){
            correctHours = "0" + correctHours;
          }
          if (correctHoursRemaining.length == 1){
            correctHoursRemaining = "0" + correctHoursRemaining;
          }

          availableTotal.value = correctHoursRemaining + ":" + correctMinutesRemaining;
          total.value = correctHours + ":" + correctMinutes;
        }
          else{
            availableTotal.value = 0;
            total.value = 0;
          }
          
          loader.close();
          alert.open("Success!", `Records was deleted successfully!`, "success");
        }catch(e){
          loader.close();
          if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
            localStorage.removeItem("t_prefab");
            localStorage.removeItem("info_prefab");
            router.push({ path: '/login'});
        }
        alert.open("Error", e, "danger");
        }
        }else{
         alert.open("Warning", 'No records selected,please try again' , "warning");
        }
      }
      modal.open(
        "Attention",
        "Do you really wish to delete those records?",
        "warning",
        "Yes",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };

    const selectAll = () =>{

     delete_array.value = data.value.map(e =>{ 

       e.checked = true; 
      return e.id
      })


      console.log(delete_array.value)
     

    }


    const unSelectAll = ()=>{
      
      delete_array.value.splice(0, delete_array.value.length)


      data.value.map(e =>{ 

        e.checked = false; 
      })

      console.log( delete_array.value)

    }




    const options = [
      { icon: "pen", path: '/records/edit', title: "Edit records" },
      { icon: "trash", action: (param) => deleteItem(param), title: "Delete records" },
    ];

    return {
      initialDate, 
      finalDate, 
      projectFilter,
      projectFilterList,
      acesso, 
      users,  
      projects,
      filterRecordsByDate,
      componentKey,
      headers,
      data,
      total,
      projectHours,
      availableTotal,
      options,
      deleteArray,
      selectAll,
      unSelectAll,
      delete_array
    };
  },

  
});
</script>
