<template>
  <div class="my-6">
    <h3 class="font-bold">
      Create a new {{ route.name.split("/")[0].slice(0, -1).toLowerCase() }}
    </h3>
    <span class="text-sm text-gray-500"
      >Fill the following information and click the <b>create</b> button to save changes.
    </span>
  </div>

  <div class="card shadow-sm bg-white border p-8">
    <span class="lg:inline-grid w-full grid-cols-3 gap-x-4 mb-6">
      <div class="flex-1 mr-3 mb-2">
        <Input
            v-model="data.users_id"
            type="select"
            label="User"
            placeholder="Select an item"
            :data="users"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
          v-model="data.projects_id"
          type="select"
          label="Project"
          placeholder="Select an item"
          :data="projects"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
          v-model="data.activities_id"
          type="select"
          label="Activity"
          placeholder="Select an item"
          :data="activities"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
          v-model="data.label_id"
          type="select"
          label="Label"
          placeholder="Select an item"
          :data="label"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
            v-model="data.categories_id"
            type="select"
            label="Category"
            placeholder="Select an item"
            :data="categories"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
            v-model="data.disciplines_id"
            type="select"
            label="Discipline"
            placeholder="Select an item"
            :data="disciplines"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
          v-model="data.description"
          type="default"
          label="Description"
          placeholder="Description"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
        <Input
          v-model="data.worked_hour"
          type="customTime"
          label="Time Spent"
          placeholder="Hours"
        />
      </div>
      <div class="flex-1 mr-3 mb-2">
          <Input
          v-model="data.worked_date"
          type="date"
          label="Worked Date"
          placeholder="Date"
        />
      </div>
    </span>
  </div>
  
  <div class="flex justify-end mt-5">
    <Button @click="create" color="primary" text="Create" />
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref, } from "vue";
import { POST, GET } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "createRecord",
  components: {
    Input,
    Button,
  },

  setup() {
    const alertProps = inject("alert");
    const data = ref({
      worked_date: "",
      worked_hour: "",
      description: "",
      projects_id: "",
      activities_id: "",
      label_id: "",
      categories_id: "",
      disciplines_id: "",
      users_id: ""
    });
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();

    const projects = ref([]);
    const activities = ref([]);
    const label = ref([]);
    const categories = ref([]);
    const disciplines = ref([]);
    const users = ref([]);

    onMounted(async() => {
      loader.open();
      const date = new Date();
      const dateArray = date.toLocaleDateString().split("T")[0].split("/");
      date.setDate(date.getDate());
      data.value.worked_date = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`; 
      try {
        const projectsResponse = await GET('projects');
        projectsResponse.map( project => projects.value.push({label: project.name, value: project.id}));

        const activitiesResponse = await GET('activities');
        activitiesResponse.map( activity => activities.value.push({label: activity.name, value: activity.id}));
        
        const labelResponse = await GET('label');
        labelResponse.map( labels => label.value.push({label: labels.name, value: labels.id}));

        const categoriesResponse = await GET('categories');
        categoriesResponse.map( category => categories.value.push({label: category.name, value: category.id}));

        const disciplinesResponse = await GET('disciplines');
        disciplinesResponse.map( discipline => disciplines.value.push({label: discipline.name, value: discipline.id}));

        const usersResponse = await GET('users');
        usersResponse.map( user => users.value.push({label: user.name, value: user.id}));
        
        loader.close();
      } catch(e) {
        loader.close();
        if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
          localStorage.removeItem("t_prefab");
          localStorage.removeItem("info_prefab");
          router.push({ path: '/login'});
        }
        alert.open("Error", e, "danger");
      }
    })

    const create = async () => {

      if (data.value.worked_hour != null) {
          let minutes = data.value.worked_hour;
          
          if (minutes == null || minutes == ""){
              data.value.worked_hour = "";
            
          } else if (minutes.length == 1){
              const newMinutes = "0" + minutes + ":00";
              minutes = newMinutes;

          } else if (minutes.length == 3){
              const newMinutes = minutes + "00";
              minutes = newMinutes;
          } else if (minutes.length == 4){
              const newMinutes = minutes + "0";
              minutes = newMinutes;
          }
  
            minutes = minutes.split(":");
            let correctMinutes = minutes[1];
            let correctHours = minutes[0]
            if (correctMinutes > 59){
                correctMinutes = 59;
            }
            if (correctHours > 23){
                correctHours = 23;
            }
            const correctTime = correctHours + ":" + correctMinutes;
            data.value.worked_hour = correctTime;
        }
      
      //validação com descripion opcional
      const { description, ...obj } = data.value;
      console.log(obj);

      const validate = isEmpty(obj);
      if (validate) {
        validate && alert.open("Attention!", validate, "warning");
      } else {
        loader.open();
        try {
          const response = await POST("hours_record", data.value);
          //console.log(response);
          alert.open(
            "Success!",
            `Record was successfully created!`,
            //<b>${response.name}</b>
            "success"
          );
          loader.close();
          router.push("/");
        } catch (e) {
          loader.close();
          if(e.includes("Token")|| e.includes("t_prefab") || e == "Unauthorized") {
            localStorage.removeItem("t_prefab");
            localStorage.removeItem("info_prefab");
            router.push({ path: '/login'});
          }
          alert.open("Error", e, "danger");
        }
      }
    };
    return {
      alertProps,
      projects,
      activities,
      label,
      categories,
      disciplines,
      users,
      data,
      create,
      route,
    };
  },
};

</script>

<style>
.ck-content {
  font-family:Lato;
  line-height: 24px;
}
.ck-editor__editable_inline {
    min-height: 400px;
}
.ck-content h2 {
  font-size: 22px;
}
.ck-content h3 {
  font-size: 20px;
}
.ck-content h4 {
  font-size: 18px;
}
.conteudo-dica {
  font-family:Lato;
  line-height: 24px;
}
.conteudo-dica h2 {
  font-size: 22px;
}
.conteudo-dica h3 {
  font-size: 20px;
}
.conteudo-dica h4 {
  font-size: 18px;
}
figure img {
  margin: 0 auto;
}
</style>