<template>
  <div class="my-6 flex items-center justify-between">
    <div>
      <h3 class="font-bold">
        Edit user > <span class="text-primary">{{ data.name }}</span>
      </h3>

      <span class="text-sm text-gray-500"
        >Verify the following information and click the <b>save</b> button to confirm changes.
      </span>
    </div>
    <div class="flex justify-end">
      <Button @click="edit" color="primary" text="Save" />
    </div>
  </div>

  <div class="card shadow-sm bg-white border p-8">
    <span class="lg:inline-grid w-full grid-cols-2 gap-x-4 mb-6">
      <div class="flex-1 mr-2 mb-3">
        <Input
          v-model="data.name"
          type="default"
          label="Name"
          placeholder="User Name"
        />
      </div>
      <div class="flex-1 mr-2 mb-3">
        <Input
          v-model="data.user_function"
          type="default"
          label="Occupation"
          placeholder="User Function"
        />
      </div>
      <div class="flex-1 mr-2 mb-3">
        <Input
          v-model="data.email"
          type="default"
          label="Email"
          placeholder="User Email"
        />
      </div>
      <!-- v-model="userPassword" -->
      <div class="flex-1 mr-2 mb-3">
        <Input
          v-model="userPassword"
          type="password"
          label="Password"
          placeholder="User Password"
        />
      </div>
    </span>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { isEmail, isEmpty } from "../../services/validate";
import { GET, PATCH } from "../../services/api";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "editUsers",
  components: {
    Input,
    Button,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal")
    const data = ref({});

    const userPassword = ref('');
    //const code = ref({});
    //const text = ref("Copiar link de acesso");

    onMounted(async () => {
      loader.open();
      try {
        data.value = await GET(`users/${route.params.id}`);
        //data.value = data.value.data;
        //data.value.status = data.value.deleted_at ? 'INATIVA' : 'ATIVA';
        //code.value = data.value.unique_code;
        loader.close();
      } catch(e) {
        loader.close();
        if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
          localStorage.removeItem("t_prefab");
          localStorage.removeItem("info_prefab");
          router.push({ path: '/login'});
        }
        alert.open("Error", e, "danger");
      }
    });

    const edit = async () => {
      //const uniqueCodeValidate = isUniqueCode(data.value.unique_code);
      const obj = {
        name: data.value.name, 
        user_function: data.value.user_function, 
        email: data.value.email, 
        //password: data.value.password, 
        role: data.value.role
        };
      console.log(obj);
      const passwordValue = userPassword.value ? userPassword.value : data.value.password;
      console.log(passwordValue);
      const emailValidate = isEmail(data.value.email);
      const validate = isEmpty(obj);
      if (validate || !emailValidate) {
        !emailValidate && alert.open("Attention!", 'Email must be valid.', "warning");
        validate && alert.open("Attention!", validate, "warning");
      } else {
        modal.open("Attention", "Do you really wish to edit this user?", "warning", "Yes" , async () => {
          try {
          loader.open();
          /*
          const year = new Date().getFullYear();
          let month = new Date().getMonth() + 1;
          month = month < 10 ? '0'+month : month;
          let day = new Date().getDate();
          day = day < 10 ? '0'+day : day;
          
          data.value['deleted_at'] = data.value.status === 'ATIVA' ? ' ' : `${year}-${month}-${day}`;*/
          if (passwordValue != null){
            obj.password = passwordValue;
          }
          console.log(passwordValue);
          const response = await PATCH(`users/${route.params.id}`, obj);
          alert.open(
            "Success!",
            `User was edited successfully!`,
            //<b>${response.name}</b>
            "success"
          );
          loader.close();
          router.push("/users");
        } catch (e) {
          loader.close();
          if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
            localStorage.removeItem("t_prefab");
            localStorage.removeItem("info_prefab");
            router.push({ path: '/login'});
          }
          alert.open("Error", e, "danger");
        }
        },
        modal.close())
      }
    };

    return { edit, data, userPassword/*copy, text*/ };
  },
};
</script>
<style>
  .ck-content {
    font-family:Lato;
    line-height: 24px;
  }
  .ck-editor__editable_inline {
    min-height: 400px;
  }
  .ck-content h2 {
    font-size: 22px;
  }
  .ck-content h3 {
    font-size: 20px;
  }
  .ck-content h4 {
    font-size: 18px;
  }
  .conteudo-dica {
    font-family:Lato;
    line-height: 24px;
  }
  .conteudo-dica h2 {
    font-size: 22px;
  }
  .conteudo-dica h3 {
    font-size: 20px;
  }
  .conteudo-dica h4 {
    font-size: 18px;
  }
  figure img {
    margin: 0 auto;
  }
</style>