<template>
  <div class="my-6 flex items-center justify-between">
    <div>
      <h3 class="font-bold">
        Edit project <span class="text-primary">{{ data.name }}</span>
      </h3>

      <span class="text-sm text-gray-500"
        >Verify the following information and click the <b>save</b> button to confirm changes.
      </span>
    </div>
    <div class="flex justify-end">
      <Button @click="edit" color="primary" text="Save" />
    </div>
  </div>

  <div class="card shadow-sm bg-white border p-8">
    <span class="lg:inline-grid w-full grid-cols-3 gap-x-4 mb-6">
      <div class="flex-1 mr-3">
        <Input
          v-model="data.name"
          type="default"
          label="Name"
          placeholder="Project Name"
        />
      </div>
      <div class="flex-1 mr-3">
        <Input
          v-model="data.sprint"
          type="default"
          label="Sprint"
          placeholder="Sprint"
        />
      </div>
      <Input
          v-model="data.required_hours"
          type="default"
          label="Required Hours"
          placeholder="Hours"
        />
    </span>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { GET, PATCH } from "../../services/api";
import { toDate } from "../../services/formater";
import { isEmpty } from "../../services/validate";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "editProjects",
  components: {
    Input,
    Button,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal")
    const data = ref({});

    onMounted(async () => {
      loader.open();
      try {
        data.value = await GET(`projects/${route.params.id}`);
        data.value.data.required_hours = (data.value.data.required_hours/60);
        data.value = data.value.data;
        /*data.value = data.value[0].plan;
        data.value.status = data.value.deleted_at ? 'INATIVA' : 'ATIVA';
        institutions.value = await GET(`institutions`);
        institutions.value.map( institution => institutions.value.push({label: institution.name, value: institution.id}));
        data.value['institutions_id'] = institutions.value.find(institution =>  institution.value === data.value['institutions_id']).label;
        data.value['accept_negative'] = data.value['accept_negative'] == 1 ? 'sim' : 'nao';*/
        loader.close();
      } catch(e) {
        loader.close();
        if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
          localStorage.removeItem("t_prefab");
          localStorage.removeItem("info_prefab");
          router.push({ path: '/login'});
        }
        alert.open("Error", e, "danger");
      }
    });

    const edit = async () => {
      modal.open("Attention", "Do you really wish to edit this project?", "warning", "Yes" , async () => {
        const obj = {name: data.value.name , sprint: data.value.sprint, required_hours: (data.value.required_hours * 60)};
        const validate = isEmpty(obj);
        if (validate) {
          validate && alert.open("Attention!", validate, "warning");
        } else {
          try {
            loader.open();
              /*if(item === 'deleted_at'){
                const year = new Date().getFullYear();
                let month = new Date().getMonth() + 1;
                month = month < 10 ? '0'+month : month;
                let day = new Date().getDate();
                day = day < 10 ? '0'+day : day;
                
                data.value['deleted_at'] = data.value.status === 'ATIVA' ? ' ' : `${year}-${month}-${day}`;
              }
            }*/
            const response = await PATCH(`projects/${route.params.id}`, obj);
            alert.open(
              "Success!",
              `Project was successfully edited!`,
              //<b>${response.name}</b>
              "success"
            );
            loader.close();
            router.push("/projects");
          } catch (e) {
            //data.value['institutions_id'] = institutions.value.find( institution => institution.value == data.value['institutions_id']).label;
            //data.value['accept_negative'] = data.value['accept_negative'] === '1' ? 'sim' : 'nao';
            loader.close();
            if(e.includes("Token") || e.includes("t_prefab") || e == "Unauthorized") {
              localStorage.removeItem("t_prefab");
              localStorage.removeItem("info_prefab");
              router.push({ path: '/login'});
            }
          alert.open("Error", e, "danger");
          }
        }
      },
      modal.close());
    };
      
    return { edit, data, toDate };
  },
};
</script>

<style>
</style>